import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import { splitHour } from "src/app/utils/utils";
import { DOCUMENT } from "@angular/common";
import { IData } from "src/app/layout/cobranza-digital/store/states/autenticated.state";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
	datax: IData;
	maintenancedark: boolean = false;
	currentDate: Date = new Date();
	dark: boolean = false;

	constructor(
		private readonly _store: Store<IAppState>,
		private readonly router: Router,
		@Inject(DOCUMENT) document: any
	) {}

	ngOnInit(): void {
		this.getAutenticatedStore();
		this.currentDate = new Date();
	}

	getAutenticatedStore() {
		this._store
			.select((state) => state.autenticated.data)
			.subscribe((response) => {
				this.datax = response;
			})
			.unsubscribe();
	}

	getFormattedDate(): string {
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric"
		} as Intl.DateTimeFormatOptions;
		return this.currentDate.toLocaleDateString("es-ES", options);
	}
  get hiddenNav(): boolean {
    const currentURL = document.location.href;
    const hiddenNavbar = [
      "beneficiarios",
      "asegurabilidad",
      "aceptar-documento"
    ];
    return !hiddenNavbar.some(element => currentURL.includes(element));
  }

}
