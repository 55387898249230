<div class="footer-area" *ngIf="hiddenNav">
	<div *ngIf="!dark" class="copyright-area">
		<div class="contenedor-info">
			<div class="row mobile">
				<div class="bc-col contenedor-logo">
					<div>
						<div class="logo-mobile">
							<bc-logo
								size="170px"
								width="5rem"
								name="grupo-bancolombia-negative"
								[ariaHidden]="false"
								alt="logo"
							></bc-logo>
						</div>
            <div class="logo-web">
							<bc-logo
								size="170px"
								width="5rem"
								name="bancolombia-horizontal-negative"
								[ariaHidden]="false"
								alt="logo"
							></bc-logo>
						</div>
					</div>
					<div class="copyright-area-dark">
						<span class="copyright">
							Copyright © 2024 Grupo Bancolombia
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
